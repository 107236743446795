// heavily inspired by: https://github.com/getsentry/sentry/issues/22715#issuecomment-926385843
import { type Scope, type Span } from "@sentry/react";
import type { SetRequired } from "type-fest";
import { ignoreErrors, ignoreWithBeforeSend } from "./ignore";
import { composeBeforeBreadcrumb, composeBeforeSend } from "./utils";
import { Params, Path } from "@sentry/react/types/types";

type SentryTypes = typeof import("@sentry/react");

const isDev =
  typeof window !== "undefined" && window.location.hostname.endsWith(".test");

const queue = [] as ((sentry: SentryTypes) => void)[];
const errorQueue = [] as Parameters<OnErrorEventHandlerNonNull>[];
const rejectionQueue = [] as PromiseRejectionEvent[];

// These functions will push calls into a queue that will be flushed once Sentry has loaded,
// they will then be replaced by direct calls to Sentry
export let addBreadcrumb: SentryTypes["addBreadcrumb"] = (...args) => {
  queue.push((x) => x.addBreadcrumb(...args));
};
export let captureMessage: SentryTypes["captureMessage"] = (...args) => {
  queue.push((x) => x.captureMessage(...args));
  return "";
};
export let captureException: SentryTypes["captureException"] = (...args) => {
  queue.push((x) => x.captureException(...args));
  return "";
};
export let captureEvent: SentryTypes["captureEvent"] = (...args) => {
  queue.push((x) => x.captureEvent(...args));
  return "";
};
export let startSpan = (
  options: Parameters<SentryTypes["startSpan"]>[0],
  callback: (span: Span | undefined) => unknown
) => {
  queue.push((x) => x.startSpan(options, callback));
};
export let withScope = (callback: (scope: Scope) => void): void => {
  queue.push((x) => x.withScope(callback));
};
export let setUser: SentryTypes["setUser"] = (...args) => {
  queue.push((x) => x.setUser(...args));
  return "";
};
export let setContext: SentryTypes["setContext"] = (...args) => {
  queue.push((x) => x.setContext(...args));
  return "";
};
export let setExtra: SentryTypes["setExtra"] = (...args) => {
  queue.push((x) => x.setExtra(...args));
  return "";
};
export let setExtras: SentryTypes["setExtras"] = (...args) => {
  queue.push((x) => x.setExtras(...args));
  return "";
};

export let setTag: SentryTypes["setTag"] = (...args) => {
  queue.push((x) => x.setTag(...args));
  return "";
};

type BaseInitOptions = Parameters<SentryTypes["init"]>[0];
type ReactRouterInstrumentationParams = Parameters<
  SentryTypes["reactRouterV6Instrumentation"]
>;

type InitOptions = SetRequired<
  Omit<
    BaseInitOptions,
    "integrations" | "replaysSessionSampleRate" | "replaysOnErrorSampleRate"
  >,
  "dsn"
> & {
  replays?: {
    sessionSampleRate?: number;
    onErrorSampleRate?: number;
  };
  tracing?: {
    reactRouter?: {
      useEffect: ReactRouterInstrumentationParams[0];
      useLocation: ReactRouterInstrumentationParams[1];
      useNavigationType: ReactRouterInstrumentationParams[2];
      createRoutesFromChildren: ReactRouterInstrumentationParams[3];
      matchRoutes: ReactRouterInstrumentationParams[4];
    };
  };
};

const getRoutingInstrumentation = (
  sentry: SentryTypes,
  options: InitOptions["tracing"]
) => {
  if (options?.reactRouter) {
    const {
      useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    } = options.reactRouter;
    return sentry.reactRouterV6BrowserTracingIntegration({
      useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    });
  }

  return undefined;
};

export const init = async (options: InitOptions) => {
  if (typeof window === "undefined") return;

  const oldOnError = window.onerror;
  const oldOnUnhandledRejection = window.onunhandledrejection;
  window.onerror = (...args) => errorQueue.push(args);
  window.onunhandledrejection = (e: PromiseRejectionEvent) =>
    rejectionQueue.push(e);

  const Sentry = await import("@sentry/react");
  window.onerror = oldOnError;
  window.onunhandledrejection = oldOnUnhandledRejection;

  Sentry.init({
    ...options,
    debug: options.debug ?? isDev,
    environment: options.environment ?? (isDev ? "development" : "production"),
    beforeSend: composeBeforeSend(ignoreWithBeforeSend, options.beforeSend),
    beforeBreadcrumb(breadcrumb, hint) {
      return composeBeforeBreadcrumb(breadcrumb, hint);
    },
    ignoreErrors: [...ignoreErrors, ...(options.ignoreErrors ?? [])],
    integrations: [
      options.tracing?.reactRouter &&
        getRoutingInstrumentation(Sentry, options.tracing),
      options.replays && Sentry.replayIntegration(),
    ].filter(Boolean),
    tracesSampleRate: options.tracesSampleRate ?? 0.1,
    replaysSessionSampleRate: options.replays?.sessionSampleRate,
    replaysOnErrorSampleRate: options.replays?.onErrorSampleRate,
  });

  // Override the placeholder functions with the real ones
  addBreadcrumb = Sentry.addBreadcrumb.bind(Sentry);
  captureMessage = Sentry.captureMessage.bind(Sentry);
  captureException = Sentry.captureException.bind(Sentry);
  captureEvent = Sentry.captureEvent.bind(Sentry);
  startSpan = Sentry.startSpan.bind(Sentry);
  withScope = Sentry.withScope.bind(Sentry);
  setUser = Sentry.setUser.bind(Sentry);
  setContext = Sentry.setContext.bind(Sentry);
  setExtra = Sentry.setExtra.bind(Sentry);
  setExtras = Sentry.setExtras.bind(Sentry);
  setTag = Sentry.setTag.bind(Sentry);

  // TODO: React ErrorBoundary

  // Replay queued calls and errors through Sentry's handlers
  queue.forEach((call) => call(Sentry));
  errorQueue.forEach((x) => window.onerror?.(...x));
  rejectionQueue.forEach((e) => window.onunhandledrejection?.(e));
};

// URL tagging
type Args = {
  useLocation: () => Path;
  useUrlParams: <P extends never>(path?: P) => Params[P];
  useSearchParams: () => [Iterable<[string, string]>, any];
  useWatch: (deps: [string], callback: () => void) => void;
  setTag: (tagName: string, value: string) => void;
};

export const useUrlTags = ({
  useLocation,
  useUrlParams,
  useSearchParams,
  useWatch,
  setTag,
}: Args) => {
  const { pathname, search } = useLocation();
  const urlParams = useUrlParams();
  const [searchParams] = useSearchParams();

  useWatch([pathname], () => {
    try {
      setTag("dojo_pathname", pathname);
      if (urlParams && typeof urlParams != "string") {
        const pathGroup = calculatePathGroup(urlParams, pathname);
        setTag("dojo_pathgroup", pathGroup);
      }
      // we do not want this to have user impact
      // eslint-disable-next-line no-catch-all/no-catch-all
    } catch (e) {
      gracefullyHandleTelemetryError(e);
    }
  });
  useWatch([search], () => {
    try {
      const paramsList: string[] = [];
      for (const [key] of searchParams) {
        paramsList.push(key);
      }
      setTag("dojo_search_params", paramsList.join(","));
      // we do not want this to have user impact
      // eslint-disable-next-line no-catch-all/no-catch-all
    } catch (e) {
      gracefullyHandleTelemetryError(e);
    }
  });
};

export const calculatePathGroup = (
  params: Record<string, string | undefined>,
  pathname: string
) => {
  const splitPath = pathname.split("/");
  const reverseParams: Record<string, string> = Object.keys(params).reduce(
    (reversed, param: string) => {
      const reversedKey: string = params[param] || "";
      return { ...reversed, [reversedKey]: param };
    },
    {}
  );
  return splitPath.reduce((newPath: string, section) => {
    if (!section) {
      return newPath;
    }
    const replacedSection = reverseParams[section]
      ? `:${reverseParams[section]}`
      : section;
    return `${newPath}/${replacedSection}`;
  });
};

const gracefullyHandleTelemetryError = (e: Error) => {
  // we do not want this to bubble up and stop the component from rendering
  captureException(e, {
    extra: { message: "error tagging urls in for telemetry" },
  });
};
