import i18n from "i18next";
import {
  formatInTimeZone as dateTzFormatInTimezone,
  format as dateTzFormat,
} from "date-fns-tz";
import { TZ, getLocalTimezoneOverride } from "./timezone";
import { dayParts } from "./dayParts";

// TODO: Probably handle format strings like "YYYY-MM-DD" and "YYYYMMDD" etc
const FormatShorthands = {
  ll: {
    year: "numeric",
    month: "short",
    day: "numeric",
  } as DateTimeFormatOptions,
  LL: { year: "numeric", month: "long", day: "numeric" },
  lll: {
    year: "numeric",
    month: "short",
    day: "numeric",
    hour: "numeric",
    minute: "2-digit",
  } as DateTimeFormatOptions,
  llll: {
    year: "numeric",
    month: "short",
    day: "numeric",
    hour: "numeric",
    weekday: "short",
    minute: "2-digit",
  } as DateTimeFormatOptions,
  "ddd MMMM Do": {
    weekday: "short",
    month: "long",
    day: "numeric",
  } as DateTimeFormatOptions,
  MMM: { month: "short" } as DateTimeFormatOptions,
  LT: { hour: "numeric", minute: "2-digit" } as DateTimeFormatOptions,
  dddd: { weekday: "long" } as DateTimeFormatOptions,
  medium: { dateStyle: "medium" } as DateTimeFormatOptions,
  full: { dateStyle: "full" } as DateTimeFormatOptions,
  long: { dateStyle: "long" } as DateTimeFormatOptions,
} as const;

export type DateTimeFormatOptions = ConstructorParameters<
  typeof Intl.DateTimeFormat
>[1];

export type DateFormatOptions =
  | (DateTimeFormatOptions & { locale?: string })
  | keyof typeof FormatShorthands;

type TranslatedString = string & {
  __tag: "TranslatedString";
};

/** **format**: this is a simple shorthand for toLocaleString, using the current
 *  locale chosen by the user in i18next. This is almost always what you want to
 *  use. In rare cases, you may need to use formatWithPattern or
 *  formatLocalTime. */
export function format(
  date: Date,
  options?: DateFormatOptions
): TranslatedString {
  if (typeof options === "undefined")
    return date.toISOString() as TranslatedString;

  if (typeof options === "string") {
    if (!(options in FormatShorthands))
      throw new Error("invalid shorthand " + options);
    options = FormatShorthands[options];
  }

  return date.toLocaleString(
    options?.locale || i18n.language,
    options
  ) as TranslatedString;
}

export function localizedWeekday(weekday: number): TranslatedString {
  if (weekday > 7) throw new Error("Numeric weekdsays must be between 0 and 6");

  const formatter = new Intl.DateTimeFormat(i18n.language, {
    weekday: "long",
  });

  // 2021 starts on a Friday, so Jan 1 is Friday, so we need to offset by 2 to get to sunday (whichis what Moment returns first)
  const date = new Date(2021, 0, weekday + 3);
  return formatter.format(date) as TranslatedString;
}

export function formatCompactDate(date: Date, timeZone?: TZ): TranslatedString {
  const { year, month, day } = dayParts(date, timeZone);

  return `${year}${month}${day}` as TranslatedString;
}

export function formatISODate(date: Date, timeZone?: TZ): TranslatedString {
  const { year, month, day } = dayParts(date, timeZone);

  return `${year}-${month}-${day}` as TranslatedString;
}

/** **formatWithPattern**: You should ideally not use this, ever. It expands bundle size and is less i18n-correct than format.
 * @deprecated.
 */
export function formatWithPattern(
  date: Date,
  pattern: string,
  { timeZone }: { timeZone?: string | null } = {}
): TranslatedString {
  const tz = timeZone ?? getLocalTimezoneOverride() ?? undefined;

  return tz
    ? (dateTzFormatInTimezone(date, tz, pattern) as TranslatedString)
    : (dateTzFormat(date, pattern) as TranslatedString);
}
