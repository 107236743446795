import { dayParts } from "./dayParts";
import { getLocalTimezoneOverride, toDate, TZ } from "./timezone";

function padded(
  input: string | number | undefined,
  length: number
): string | undefined {
  return input?.toString()?.padStart(length, "0");
}

export function set(
  date: Date,
  options: {
    year?: number;
    month?: number;
    day?: number;
    hours?: number;
    minutes?: number;
    seconds?: number;
    milliseconds?: number;
    timeZone?: TZ;
  }
): Date {
  const timeZone = options.timeZone ?? getLocalTimezoneOverride() ?? undefined;
  const { year, month, day, hours, minutes, seconds, milliseconds } = dayParts(
    date,
    timeZone
  );

  if (
    typeof options.month === "number" &&
    (options.month < 0 || options.month >= 12)
  ) {
    throw new Error(`Invalid month ${options.month}`);
  }
  if (
    typeof options.day === "number" &&
    (options.day < 1 || options.day > 31)
  ) {
    throw new Error(`Invalid day ${options.day}`);
  }
  if (
    typeof options.hours === "number" &&
    (options.hours < 0 || options.hours > 23)
  ) {
    throw new Error(`Invalid hours ${options.hours}`);
  }
  if (
    typeof options.minutes === "number" &&
    (options.minutes < 0 || options.minutes > 59)
  ) {
    throw new Error(`Invalid minutes ${options.minutes}`);
  }
  if (
    typeof options.seconds === "number" &&
    (options.seconds < 0 || options.seconds >= 60)
  ) {
    throw new Error(`Invalid seconds ${options.seconds}`);
  }

  if (
    typeof options.milliseconds === "number" &&
    (options.milliseconds < 0 || options.milliseconds >= 1000)
  ) {
    throw new Error(`Invalid milliseconds ${options.milliseconds}`);
  }

  const toParse = `${padded(options.year, 4) ?? year}-${
    typeof options.month !== "undefined"
      ? padded(options.month + 1, 2)
      : undefined ?? month
  }-${padded(options.day, 2) ?? day}T${padded(options.hours, 2) ?? hours}:${
    padded(options.minutes, 2) ?? minutes
  }:${padded(options.seconds, 2) ?? seconds}.${
    padded(options.milliseconds, 3) ?? milliseconds
  }`;

  return toDate(toParse, { timeZone });
}

export function setYear(date: Date, year: number, timeZone?: TZ): Date {
  return set(date, { year, timeZone });
}

export function setMonth(date: Date, month: number, timeZone?: TZ): Date {
  return set(date, { month, timeZone });
}

export function setDay(date: Date, day: number, timeZone?: TZ): Date {
  return set(date, { day, timeZone });
}

export function setHours(date: Date, hours: number, timeZone?: TZ): Date {
  return set(date, { hours, timeZone });
}

export function setMinutes(date: Date, minutes: number, timeZone?: TZ): Date {
  return set(date, { minutes, timeZone });
}

export function setSeconds(date: Date, seconds: number, timeZone?: TZ): Date {
  return set(date, { seconds, timeZone });
}

export function setMilliseconds(
  date: Date,
  milliseconds: number,
  timeZone?: TZ
): Date {
  return set(date, { milliseconds, timeZone });
}
