import i18next from "i18next";

// See https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/Locale/getWeekInfo for details:
type CompatibleIntlLocale =
  | { getWeekInfo(): { firstDay: number } }
  | { weekInfo: { firstDay: number } };

function clamp(value: number): 0 | 1 | 2 | 3 | 4 | 5 | 6 {
  if (value < 0) return 0;
  return (Math.floor(value) % 7) as 0 | 1 | 2 | 3 | 4 | 5 | 6;
}

export function weekStartsOn(): 0 | 1 | 2 | 3 | 4 | 5 | 6 {
  // if i18next isn't initialized or working, default to sunday:
  if (!i18next.language) {
    return 0;
  }

  const locale = new Intl.Locale(
    i18next.language
  ) as unknown as CompatibleIntlLocale;

  if ("getWeekInfo" in locale && typeof locale.getWeekInfo === "function") {
    return clamp(locale.getWeekInfo().firstDay);
  }
  if ("weekInfo" in locale && typeof locale.weekInfo === "object") {
    return clamp(locale.weekInfo.firstDay);
  }

  // default to sunday:
  return 1;
}
