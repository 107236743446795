import { toDate } from "./timezone";
import {
  addDays,
  endOfHour,
  endOfMinute,
  endOfSecond,
  startOfHour,
  startOfMinute,
  startOfSecond,
  subDays,
} from "date-fns";
import { weekStartsOn } from "./weekStartsOn";
import { getLocalTimezoneOverride } from "./timezone";
import { dayParts } from "./dayParts";

export type TimeZone = string;
export type Mode =
  | "day"
  | "week"
  | "hour"
  | "minute"
  | "second"
  | "month"
  | "year";

// fun hack, this takes the output of a rendered month (i.e. 1-12), and
// new Date() takes 0-11, so we can just pass the month directly to new Date,
// with the date of zero, which takes you to the end of the last month:
function daysInMonth(year: number, month: number) {
  return new Date(year, month, 0).getDate();
}

export function endOf(date: Date, mode: Mode, timeZone?: TimeZone) {
  timeZone ??= getLocalTimezoneOverride() || undefined;

  if (mode === "second") return endOfSecond(date);
  if (mode === "minute") return endOfMinute(date);
  if (mode === "hour") return endOfHour(date);

  const { year, month, day, weekday } = dayParts(date, timeZone);

  if (mode === "day") {
    return toDate(`${year}-${month}-${day}T23:59:59.999`, { timeZone });
  }

  if (mode === "week") {
    const diff = 6 - (weekday - weekStartsOn());

    const eow = addDays(date, diff);

    return endOf(eow, "day", timeZone);
  }

  if (mode === "month") {
    return toDate(
      `${year}-${month}-${daysInMonth(
        parseInt(year),
        parseInt(month)
      )}T23:59:59.999`,
      {
        timeZone,
      }
    );
  }

  if (mode === "year") {
    return toDate(`${year}-12-31T23:59:59.999`, { timeZone });
  }

  throw new Error(`Unsupported endOf ${mode}`);
}

export function startOf(date: Date, mode: Mode, timeZone?: TimeZone) {
  timeZone ??= getLocalTimezoneOverride() || undefined;

  if (mode === "second") return startOfSecond(date);
  if (mode === "minute") return startOfMinute(date);
  if (mode === "hour") return startOfHour(date);

  const { year, month, day, weekday } = dayParts(date, timeZone);

  if (mode === "day") {
    return toDate(`${year}-${month}-${day}T00:00:00.000`, { timeZone });
  }

  if (mode === "week") {
    const diff = weekday - weekStartsOn();

    const sunday = subDays(date, diff);

    return startOf(sunday, "day", timeZone);
  }

  if (mode === "month") {
    return toDate(`${year}-${month}-01T00:00:00.000`, { timeZone });
  }

  if (mode === "year") {
    return toDate(`${year}-01-01T00:00:00.000`, { timeZone });
  }

  throw new Error(`Unsupported startOf ${mode}`);
}
