/** useSideEffectOnMount: is a hook that allows you to run a callback that has side effects when the component is first mounted.
 * If you are using this without side effects, use useOnFirstRender instead
 * @param callback - The callback to run when the component is mounted.
 */
import { useEffect, useRef } from "react";

export default function useSideEffectOnMount(callback: () => void) {
  const firstRun = useRef<boolean>(true);

  // this useEffect is needed as the callback sometimes has async side effects that need to be syncronized with the render loop

  useEffect(() => {
    if (firstRun.current) {
      firstRun.current = false;
      callback();
    }
  }, [callback]);
}
